import {createAction} from 'redux-actions';
import {batchActions} from 'redux-batched-actions';
import Request from 'app/common/Request';
import * as actions from 'app/distributors/DistributorsConstants';
import {showLoader, hideLoader, handleError} from 'app/app/AppActions';
import {getDistributors} from 'app/distributors/DistributorsSelectors';

export const setDistributors = createAction(
	actions.SET_DISTRIBUTORS,
	distributors => ({distributors}),
);

export const setDistributor = createAction(
	actions.SET_DISTRIBUTOR,
	distributor => ({distributor}),
);

export const deleteDistributor = createAction(
	actions.DELETE_DISTRIBUTOR,
	distributor => ({distributor}),
);

export const setDistributorRelations = createAction(
	actions.USER_DISTRIBUTOR_SAVE_RELATIONS,
	(partners, competitors) => ({partners, competitors}),
);

export const selectDistributorRelation = createAction(
	actions.SELECT_DISTRIBUTOR_RELATION,
	(relationType, distributorID) => ({relationType, distributorID}),
);

export const getDistributorsRequest = () => dispatch => {
	dispatch(showLoader());

	return new Request().get('/api/distributors')
		.then(response => {
			dispatch(batchActions([
				hideLoader(),
				setDistributors(response.list),
			]));
		})
		.catch(error => dispatch(handleError(error)));
};

export const saveDistributorRelations = ({partners, competitors}) => dispatch => (
	new Request().post('/api/distributors/edit', {partners, competitors})
		.then(() => {
			dispatch(setDistributorRelations(partners, competitors));
		})
		.catch(error => {
			throw new Error(error);
		})
);

export const createDistributorRequest = distributor => (dispatch, getState) => {
	const distributors = getDistributors(getState());
	dispatch(showLoader());

	return new Request().post('/api/distributors', distributor)
		.then(response => {
			dispatch(batchActions([
				hideLoader(),
				setDistributors([...distributors, response]),
			]));

			return response;
		})
		.catch(error => dispatch(handleError(error)));
};

export const editDistributorRequest = distributor => dispatch => {
	dispatch(showLoader());

	return new Request().put(`/api/distributors/${distributor.id}`, distributor)
		.then(response => {
			dispatch(batchActions([
				hideLoader(),
				setDistributor(response),
			]));

			return response;
		})
		.catch(error => dispatch(handleError(error)));
};

export const loadDistributorLogoRequest = (logo, distributor) => dispatch => {
	dispatch(showLoader());
	new Request().upload(`/api/distributors/${distributor.id}/logo`, {logo}, {method: 'PATCH'})
		.then(response => (
			dispatch(batchActions([
				hideLoader(),
				setDistributor({...distributor, logo: response.logo}),
			]))
		))
		.catch(error => dispatch(handleError(error)));
};

export const deleteDistributorRequest = distributor => dispatch => {
	dispatch(showLoader());
	new Request().delete(`/api/distributors/${distributor.id}`, distributor)
		.then(() => (
			dispatch(batchActions([
				hideLoader(),
				deleteDistributor(distributor),
			]))
		)).catch(error => dispatch(handleError(error)));
};
