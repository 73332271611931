import {createAction} from 'redux-actions';
import {batchActions} from 'redux-batched-actions';
import Request from 'app/common/Request';
import {getUserCurrentCinemaID} from 'app/user/userSelectors';
import {updateCinemaTmsSettings} from 'app/user/actions/UserActions';
import * as types from 'app/tms_playlists/constants/PlaylistsConstants';
import {
	showLoader,
	hideLoader,
	showPopup,
	handleError,
	addErrorToast,
	addSuccessToast,
} from 'app/app/AppActions';
import {hidePopup} from 'app/app/appHelpers';
import {
	getAutouploadsErrorMessage,
	getRequestErrorMessage,
} from 'app/tms_playlists/selectors/PlaylistsSelectors';

const setPlayservers = createAction(
	types.SET_PLAYSERVERS,
	payload => payload,
);

export const setPlaylists = createAction(
	types.SET_PLAYLISTS,
	payload => payload,
);

const setLastUCSDownloadDate = createAction(
	types.SET_LAST_UCS_DOWNLOAD_DATE,
	commonUCSDownloadDate => ({commonUCSDownloadDate}),
);

export const setGeneratingDays = createAction(
	types.SET_GENERATING_DAYS,
	dates => ({dates}),
);

export const initializePlaylistSocketQueue = createAction(
	types.PLAYLIST_INITIALIZE_SOCKET_QUEUE,
);

export const applyPlaylistSocketQueue = createAction(
	types.PLAYLIST_APPLY_SOCKET_QUEUE,
);

export const addPlaylistsToSocketQueue = createAction(
	types.PLAYLIST_ADD_TO_SOCKET_QUEUE,
	message => ({message}),
);

export const getPlayserversAndUCS = (cinemaID, date) => (dispatch, getState) => {
	const momentDate = moment(date);
	const state = getState();

	return Promise.all([
		new Request().get(`/api/tms/playlists/playservers?cinema_id=${cinemaID}`),
		new Request().get(`/api/tms/v3/${cinemaID}/ucs_last_update`),
	])
		.then(response =>
			_.object(['playservers', 'commonUCSDownloadDate'], response))
		.then(({playservers: {data: playserversData}, commonUCSDownloadDate: {time}}) => {
			dispatch(batchActions([
				setLastUCSDownloadDate(time),
				setPlayservers(playserversData),
			]));
		})
		.catch(error => {
			dispatch(batchActions([
				handleError(error),
				addErrorToast({
					subtitle: i18n.t('notifications.ConnectionError'),
				}),
			]));
		});
};

export const setPreloadAccessStatus = createAction(
	types.PLAYLIST_SET_PRELOAD_ACCESS_STATUS,
	status => ({status}),
);

export const getLastUCSDownloadDate = cinemaID => dispatch =>
	new Request().get(`/api/tms/v3/${cinemaID}/ucs_last_update`)
		.then(({time}) => dispatch(setLastUCSDownloadDate(time)))
		.catch(() => {
			dispatch(addErrorToast({
				subtitle: i18n.t('notifications.ConnectionError'),
			}));
		});

export const changeDate = createAction(
	types.PLAYLISTS_CHANGE_DATE,
	date => date.startOf('week'),
);

export const updatePlaylists = createAction(
	types.PLAYLISTS_UPDATE,
	shows => ({shows}),
);

export const updateNewPlaylists = createAction(
	types.PLAYLISTS_NEW_UPDATE,
	playlists => playlists,
);

export const updateGenerationStatus = createAction(
	types.PLAYLISTS_UPDATE_GENERATION_STATUS,
	playlists => playlists,
);

export const setUploadingData = createAction(
	types.PLAYLISTS_SET_UPLOADING_DATA,
	playlist => playlist,
);

export const addPlaylists = createAction(
	types.PLAYLISTS_ADD,
	shows => ({shows}),
);

export const deletePlaylists = createAction(
	types.PLAYLISTS_DELETE,
	ids => ({ids}),
);

const setPlaylistsUploadTimeouts = createAction(
	types.SET_UPLOAD_PLAYLISTS_TIMEOUTS,
	halls => ({halls}),
);

const clearPlaylistsUploadTimeouts = createAction(
	types.CLEAR_UPLOAD_PLAYLISTS_TIMEOUTS,
	halls => ({halls}),
);

export const setGeneratingDaysByHall = createAction(
	types.SET_GENERATING_DAYS_BY_HALL,
	(halls, dateStart, dateEnd) => ({halls, dateStart, dateEnd}),
);

export const removeGeneratingDayByHall = createAction(
	types.REMOVE_GENERATING_DAY_BY_HALL,
	(hallId, date) => ({hallId, date}),
);

export const resetStatusGenerating = createAction(
	types.RESET_STATUS_GENERATING,
);

export function handleUploadToTms({start, end, halls, cinemaID}) {
	return dispatch => {
		const hallIDs = _.pluck(halls, 'id');

		dispatch(batchActions([
			setPlaylistsUploadTimeouts(hallIDs),
			showLoader(),
		]));

		setTimeout(() => {
			dispatch(clearPlaylistsUploadTimeouts(hallIDs));
		}, 15000);

		return new Request().post(`/api/tms/playlists/upload?cinema_id=${cinemaID}`, {
			hall_ids: hallIDs,
			date_start: start,
			date_end: end,
		})
			.then(() => {
				dispatch(batchActions([
					hideLoader(),
					showPopup(null),
					addSuccessToast({
						title: i18n.t('tmsPlaylists:TimeWarning'),
					}),
				]));
			})
			.catch(error => {
				dispatch(batchActions([
					handleError(error),
					addErrorToast({
						subtitle: i18n.t('tmsPlaylists:UploadError'),
					}),
				]));
			});
	};
}

/**
 * Make request to set auto_upload_playlists flag for cinema.
 *
 * @param {number} cinemaID
 * @param {boolean} flagValue
 */
export function toggleAutoUploadRequest(cinemaID, flagValue) {
	return async function(dispatch) {
		try {
			const result = await new Request().post(`/api/tms/${cinemaID}/settings/auto_upload_playlists`, {
				auto_upload_playlists: flagValue,
			});

			dispatch(updateCinemaTmsSettings({
				cinema_id: cinemaID,
				auto_upload_playlists: flagValue,
			}));

			return result;
		} catch (error) {
			const code = _.get(JSON.parse(error.response), 'code');

			if (code) {
				dispatch(batchActions([
					updateCinemaTmsSettings({
						cinema_id: cinemaID,
						auto_upload_playlists: flagValue,
					}),
					addErrorToast({
						subtitle: i18n.t(getAutouploadsErrorMessage(code)),
					}),
				]));
			} else {
				dispatch(addErrorToast({
					subtitle: i18n.t(getRequestErrorMessage(error.status)),
				}));
			}
		}
	};
}

export const getPreloadStatusRequest = cinemaID => async dispatch => {
	try {
		const {count} = await new Request().get(`/api/tms/shows/v3/${cinemaID}/preload/status`);

		dispatch(setPreloadAccessStatus(Boolean(count)));
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const getPreloadStatusRequestDebounced = _.debounce(
	(cinemaID, dispatch) => dispatch(getPreloadStatusRequest(cinemaID)), 5000,
);

// Generation
const checkPlaylistsGenerationSuccess = createAction(
	types.CHECK_PLAYLISTS_GENERATION_SUCCESS,
	checkItems => ({checkItems}),
);

const setPlaylistsFetchingStatus = createAction(
	types.SET_PLAYLISTS_FETCHING_STATUS,
	status => ({status}),
);

const setFetchingGeneration = createAction(
	types.SET_FETCHING_GENERATION,
	status => ({status}),
);

export const checkPlaylists = (dateStart, dateEnd, halls) => async (dispatch, getState) => {
	try {
		dispatch(setPlaylistsFetchingStatus(true));
		const cinemaID = getUserCurrentCinemaID(getState());
		const params = decodeURIComponent($.param({
			date_start: dateStart.format(),
			date_end: dateEnd.format(),
			halls: halls.join(','),
		}));

		const response = await new Request().get(`/api/tms/shows/v3/${cinemaID}/check_playlist?${params}`);
		dispatch(batchActions([
			setPlaylistsFetchingStatus(false),
			checkPlaylistsGenerationSuccess(response),
		]));
	} catch (error) {
		dispatch(batchActions([
			setPlaylistsFetchingStatus(false),
			handleError(error),
		]));
	}
};

export function generatePlaylists(dateStart, dateEnd, halls) {
	return (dispatch, getState) => {
		dispatch(setFetchingGeneration(true));
		const cinemaID = getUserCurrentCinemaID(getState());
		dispatch(setGeneratingDaysByHall(halls, dateStart, dateEnd));

		return new Request().post(`/api/tms/playlists/generate?cinema_id=${cinemaID}`, {
			hall_ids: halls,
			date_start: dateStart.format(),
			date_end: dateEnd.format(),
		})
			.then(() => {
				dispatch(setFetchingGeneration(false));
				hidePopup()(dispatch);
			})
			.catch(() => {
				dispatch(batchActions([
					setFetchingGeneration(false),
					setGeneratingDaysByHall([], dateStart, dateEnd),
					addErrorToast({
						subtitle: i18n.t('tmsPlaylists:UploadError'),
					}),
				]));
			});
	};
}
